<div class="container-fluid p-0 pb-4 con">
    <div class="topbar">
        <img [routerLink]="['/']" src="../../../assets/img/easy.jpg" alt="logo"/>
        <ul class="menu">
          <li [routerLink]="['/']">Accueil</li>
          <li>
            <button
              mat-button
              [matMenuTriggerFor]="belowMenu"
              #menuTrigger="matMenuTrigger"
              (mouseenter)="openResourceMenu(); recheckIfInMenu = true"
              (mouseout)="closeResourceMenu(); recheckIfInMenu = false"
              class="custom-menu-trigger"
              style="color: #707070;"
            >
              Nos partenaires
            </button>
            <mat-menu
              #belowMenu="matMenu"
              yPosition="below"
              class="mat-mdc-menu-panel"
              [hasBackdrop]="false"
            >
              <span
                (mouseenter)="recheckIfInMenu = true"
                (mouseleave)="closeResourceMenu(); recheckIfInMenu = false"
              >
                <button mat-menu-item [routerLink]="['/banques']">Les banques</button>
                <button mat-menu-item [routerLink]="['/entreprises']">Les entreprises</button>
              </span>
            </mat-menu>
          </li>
          <li [routerLink]="['/procédure']">Comment ça marche ?</li>
          <li [routerLink]="['/contact-nous']">Contactez Nous !</li>
        </ul>
        <div class="toggle-menu" (click)="toggleMenu()">
          <mat-icon>reorder</mat-icon>
        </div>
        <li class="cnx" [routerLink]="['/client']"><u>Se connecter</u></li>
      </div>

      <ul class="mobile-menu" *ngIf="isMenuOpen">
        <li [routerLink]="['/']">Accueil</li>
        <li class="dropdown">
          <button mat-button (click)="togglePartnerMenu()" class="custom-menu-trigger">
            Nos partenaires
            <mat-icon style="height: 18px;">keyboard_arrow_down</mat-icon>
    
          </button>
          <ul class="partner-menu" *ngIf="isPartnerMenuOpen">
            <li><a [routerLink]="['/banques']" style="color: black">Les banques</a></li>
            <li><a [routerLink]="['/entreprises']" style="color: black">Les entreprises</a></li>
            <li><a [routerLink]="['/contact-nous']" style="color: black">Contactez Nous !</a></li>
          </ul>
        </li>
        <li [routerLink]="['/procédure']">Comment ça marche ?</li>
        <li class="cnx" [routerLink]="['/client']"><u>Se connecter</u></li>
      </ul>

<div class="contact-container">
    <div class="right-col">
  
      <h1 style="margin-top: -30px">Contact</h1>
      <div style="margin-top: -30px ">
        <p class="content"> Pour assurer un traitement efficace de votre demande, veuillez nous préciser l'objet.</p>
        <p >Sinon vous pouvez nous contacter sur: <br ><b><mat-icon style="height: 18px; padding-bottom: 21px;">phone</mat-icon>
          +216 42 550 600</b> / <b><mat-icon style="height: 18px; padding-bottom: 21px;">mail</mat-icon> contact@easybank.tn</b></p>
  
        <form style="margin-top: 20px" [formGroup]="form">
          <label >Objet de votre message</label>
          <input type="text" placeholder="Objet" formControlName="objet" required>
          <label >Message</label>
          <textarea rows="6" placeholder="Votre message" formControlName="message" required></textarea>
          <!--<a href="javascript:void(0)">--><button type="submit" class="button2" (click)="envoyer()">Envoyer</button><!--</a>-->
        </form>
      </div>
    </div>
  </div>
  
  
</div>