import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {Page404Component} from './pages/page404/page404.component';


import { SimulatorComponent } from './pages/simulator/simulator.component';
import { AuthGuard } from './core/guard/auth/auth.guard';
import {BanklistComponent} from './pages/banklist/banklist.component';
import {EntrepriseListComponent} from './pages/entreprise-list/entreprise-list.component';
import {LoginClientComponent} from './pages/login-client/login-client.component';
import {InscriptionComponent} from './pages/inscription/inscription.component';
import {ProcedureComponent} from './pages/procedure/procedure.component';
import { ContactNousComponent } from './pages/contact-nous/contact-nous.component';

const routes: Routes = [
  { path: '', redirectTo: '/admin', pathMatch: 'full' },

  {
    path: '',
    loadChildren: () =>
      import('./basic/basic.module').then((m) => m.BasicModule),

    // canActivate: [LoginGuard],
  },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashbord/dashbord.module').then((m) => m.DashbordModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'dashboard/client',
    loadChildren: () =>
      import('./pages/client-dashboard/client-dashboard.module').then((m) => m.ClientDashboardModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'not-found',
    component: Page404Component,
  },
  {
    path: 'simulateur',
    component: SimulatorComponent,
  },
  {
    path: 'contact-nous',
    component: ContactNousComponent,
  },
  {
    path: 'procédure',
    component: ProcedureComponent,
  },
  {
    path: 'banques',
    component: BanklistComponent,
  },
  {
    path: 'client',
    component: LoginClientComponent,
  },
  {
    path: 'entreprises',
    component: EntrepriseListComponent,
  },
  {
    path: 'inscription',
    component: InscriptionComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
}
