<div class="container-fluid p-0 pb-4 con">
  <div class="topbar">
    <img [routerLink]="['/']" src="../../../assets/img/easy.jpg" alt="logo"/>
    <ul class="menu">
      <li [routerLink]="['/']">Accueil</li>
      <li>
        <button
          mat-button
          [matMenuTriggerFor]="belowMenu"
          #menuTrigger="matMenuTrigger"
          (mouseenter)="openResourceMenu(); recheckIfInMenu = true"
          (mouseout)="closeResourceMenu(); recheckIfInMenu = false"
          class="custom-menu-trigger"
          style="color: #707070;"
        >
          Nos partenaires
        </button>
        <mat-menu
          #belowMenu="matMenu"
          yPosition="below"
          class="mat-mdc-menu-panel"
          [hasBackdrop]="false"
        >
          <span
            (mouseenter)="recheckIfInMenu = true"
            (mouseleave)="closeResourceMenu(); recheckIfInMenu = false"
          >
            <button mat-menu-item [routerLink]="['/banques']">Les banques</button>
            <button mat-menu-item [routerLink]="['/entreprises']">Les entreprises</button>
          </span>
        </mat-menu>
      </li>
      <li [routerLink]="['/procédure']">Comment ça marche ?</li>
      <li [routerLink]="['/contact-nous']">Contactez Nous !</li>
    </ul>
    <div class="toggle-menu" (click)="toggleMenu()">
      <mat-icon>reorder</mat-icon>
    </div>
    <li class="cnx" [routerLink]="['/client']"><u>Se connecter</u></li>
  </div>
  <ul class="mobile-menu" *ngIf="isMenuOpen">
    <li [routerLink]="['/']">Accueil</li>
    <li class="dropdown">
      <button mat-button (click)="togglePartnerMenu()" class="custom-menu-trigger">
        Nos partenaires
        <mat-icon style="height: 18px;">keyboard_arrow_down</mat-icon>

      </button>
      <ul class="partner-menu" *ngIf="isPartnerMenuOpen">
        <li><a [routerLink]="['/banques']" style="color: black">Les banques</a></li>
        <li><a [routerLink]="['/entreprises']" style="color: black">Les entreprises</a></li>
        <li><a [routerLink]="['/contact-nous']" style="color: black">Contactez Nous !</a></li>
      </ul>
    </li>
    <li [routerLink]="['/procédure']">Comment ça marche ?</li>
    <li class="cnx" [routerLink]="['/client']"><u>Se connecter</u></li>
  </ul>
  <div class="main">
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
      <!-- first Step -->
      <mat-step [stepControl]="statusForm">
        <form class="pb-3" [formGroup]="statusForm">
          <h4>quel est votre statut professionnel ?</h4>
          <div class="wrapper">
            
            <input
              type="radio"
              value="titulaire"
              formControlName="select"
              id="titulaire"
            />
            <label for="titulaire" class="option">
              <span>Salarié Titulaire</span>
              <div class="dot"></div>
            </label>
            <input
              type="radio"
              value="professionnel"
              formControlName="select"
              id="professionnel"
            />
            <label for="professionnel" class="option">
              <span>Professionnel</span>
              <div class="dot"></div>
            </label>
            <input
              type="radio"
              value="contractuel"
              formControlName="select"
              id="contractuel"
            />
            <label for="contractuel" class="option">
              <span>Salarié Contractuel</span>
              <div class="dot"></div>
            </label>
          </div>
          <!-- <ul>
            <li>
              <input type="checkbox" />
              <span>Salarié contractuel</span>
            </li>
            <li>
              <input type="checkbox" />
              <span>Salarié titulaire</span>
            </li>
            <li>
              <input type="checkbox" />
              <span>Professionel</span>
            </li>
          </ul> -->
          <div class="buttonsContainer">
            <button class="nextBtn btnHover" (click)="firstform()">Suivant</button>
          </div>
        </form>
      </mat-step>
      <!-- Second Step -->
      <mat-step [stepControl]="natureCreditForm">
        <form class="pb-3" [formGroup]="natureCreditForm">
          <!-- <ng-template matStepLabel matStepperIcon="edit"> </ng-template> -->
          <h4>Nature de financement solicité</h4>
          <div class="wrapper">
            <input
              type="radio"
              value="consommation"
              formControlName="nature"
              id="option-1"
            />
            <label for="option-1" class="option">
              <span>Crédit Consommation</span>
              <div class="dot"></div>
            </label>
            <input
              type="radio"
              value="auto"
              formControlName="nature"
              (onchange)="changeApport()"
              id="option-2"
            />
            <label for="option-2" class="option">
              <span>Crédit Auto</span>
              <div class="dot"></div>
            </label>
            <input
              type="radio"
              value="immobilier"
              formControlName="nature"
              (onchange)="changeApport()"
              id="option-3"
            />
            <label for="option-3" class="option">
              <span>Crédit Immobilier</span>
              <div class="dot"></div>
            </label>
            <!-- <input type="radio" name="select" id="option-4" />
            <label for="option-4" class="option">
              <span>Crédit Professionel</span>
              <div class="dot"></div>
            </label> -->
            <!-- <input type="radio" name="select" id="option-5" />
            <label for="option-5" class="option">
              <span>Rachat de credit</span>
              <div class="dot"></div>
            </label> -->
          </div>
          <div class="buttonsContainer">
            <button class="prevBtn" matStepperPrevious>Précédent</button>
            <button
              class="nextBtn btnHover"
              matStepperNext
              (click)="natureCreditCheck()"
            >
              Suivant
            </button>
          </div>
        </form>
      </mat-step>
      <!-- Third Step -->
      <mat-step [stepControl]="revenuForm">
        <form class="pb-3" [formGroup]="revenuForm">
          <h4>Revenu</h4>
          <!-- <ng-template matStepLabel matStepperIcon="edit"> </ng-template> -->
          <div class="form-group">
            <label for="">Salaire annuel brute</label>
            <div class="dt">
              <input
                formControlName="salaire"
                min="0"
                type="number"
                [ngClass]="{ 'is-invalid': submitted1 && f1.salaire.errors && f1.salaire.value < 1 }"
                placeholder="0"
                class="form-control"
              />
              <span>DT</span>
            </div>
          </div>
          <div *ngIf="submitted1 && f1.salaire.errors && f1.salaire.value < 1" class="invalid-feedback">
            <div>Salaire annuel brute est obligatoire et doit être superieur à 0.000 dt</div>
          </div>
          <div class="form-group">
            <label for="">Dividendes </label>

            <div class="dt">
              <input
                formControlName="dividendes"
                type="number"
                min="0"
                placeholder="0"
                class="form-control"
              />
              <span>DT</span>
            </div>
          </div>
          <div class="form-group">
            <label for="">Autres</label>

            <div class="dt">
              <input
                formControlName="autre"
                type="number"
                min="0"
                placeholder="0"
                class="form-control"
              />
              <span>DT</span>
            </div>
          </div>

          <div class="buttonsContainer">
            <button class="prevBtn" matStepperPrevious>Précédent</button>
            <button class="nextBtn btnHover" (click)="revenueCheck()">Suivant</button>
          </div>
        </form>
      </mat-step>
      <!-- Fourth Step -->
      <mat-step [stepControl]="montantForm">
        <form class="pb-3" [formGroup]="montantForm">
          <!-- <ng-template matStepLabel matStepperIcon="edit"> </ng-template> -->
          <div class="form-group">
            <label for="">Montant demandé</label>
            <div class="dt">
              <input
                type="number"
                min="0"
                formControlName="montant"
                class="form-control"
                placeholder="0"
                [ngClass]="{ 'is-invalid': submitted2 && f2.montant.errors }"
                required
                (input)="inpmontant($event)"
              />
              <span>DT</span>
            </div>
            <div *ngIf="submitted2 && f2.montant.errors" class="invalid-feedback">
              <div>Montant est obligatoire</div>
            </div>
          </div>
          <div class="form-group">
            <label for="">Apport Personnel interne</label>
            <h6 class="appersonel">{{ apportPersonnel }} DT</h6>
            <div class="range-wrap" *ngIf="isImmobiler">
              <input
                #range
                type="range"
                class="range mt-3"
                value="20"
                [min]="minapport"
                max="100"
                (input)="setBuble(range, bubble)"
              />
              <output #bubble class="bubble"></output>
            </div>
            <div class="range-wrap" *ngIf="isauto">
              <input
                #range
                type="range"
                class="range mt-3"
                value="40"
                [min]="minapport"
                max="100"
                (input)="setBuble(range, bubble)"
              />
              <output #bubble class="bubble"></output>
            </div>
            <div class="range-wrap" *ngIf="isConsommation">
              <input
                #range
                type="range"
                class="range mt-3"
                value="0"
                [min]="minapport"
                max="100"
                (input)="setBuble(range, bubble)"
              />
              <output #bubble class="bubble"></output>
            </div>
          </div>
          <div class="form-group">
            <label for="">Durée</label>
            <div class="range-wrap">
              <input
                #range2
                type="range"
                class="range mt-2"
                value="1"
                min="1"
                [max]="maxAnnee"
                (input)="setDuree(range2, bubble2)"
              />
              <output #bubble2 class="bubble"></output>
            </div>
          </div>
          <div class="buttonsContainer">
            <button class="prevBtn" matStepperPrevious>Précédent</button>
            <button class="nextBtn btnHover" (click)="checkMontant()" matStepperNext>
              Suivant
            </button>
          </div>
        </form>
      </mat-step>

      <!-- Fifth Step -->
      <mat-step [stepControl]="banqueForm">
        <form class="pb-3" [formGroup]="banqueForm">
          <!-- <ng-template matStepLabel matStepperIcon="edit"> </ng-template> -->
          <h4>Relation avec la banque</h4>
          <div class="form-group">
            <label for="">Quelle est votre banque</label>

            <select formControlName="nomBanque" class="form-control">
              <option value=""></option>
              <option *ngFor="let item of banquesList" [value]="item._id">
                {{ item.bankName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Avez-vous un crédit en cours ?</label>
            <div class="wrap">
              <input
                #creditoui
                formControlName="creditEncours"
                type="radio"
                value="true"
                id="oui"
              />
              <label for="oui" class="option">
                <span>Oui</span>
                <div class="dot"></div>
              </label>
              <input
                id="non"
                type="radio"
                formControlName="creditEncours"
                value="false"
              />
              <label for="non" class="option">
                <span>Non</span>
                <div class="dot"></div>
              </label>
            </div>
          </div>
          <!-- <div
            *ngIf="banqueForm.value.creditEncours == 'true'"
            class="form-group"
          >
            <label for="">Quelle est votre mensualité</label>

            <input
              type="number"
              formControlName="mensualiteCredit"
              class="form-control"
              required
            />
          </div> -->
          <div class="form-group">
            <label>Avez-vous des chéques impayés ?</label>
            <div class="wrap">
              <input
                #credit
                id="chequeoui"
                type="radio"
                formControlName="checkImpayé"
                value="true"
              />
              <label for="chequeoui" class="option">
                <span>Oui</span>
                <div class="dot"></div>
              </label>
              <input
                id="chequenon"
                type="radio"
                formControlName="checkImpayé"
                value="false"
              />
              <label for="chequenon" class="option">
                <span>Non</span>
                <div class="dot"></div>
              </label>
            </div>
          </div>
          <div class="buttonsContainer">
            <button class="prevBtn" matStepperPrevious>Précédent</button>
            <button class="nextBtn btnHover" (click)="banqueCheck()">Suivant</button>
          </div>
        </form>
      </mat-step>

      <!-- six Step -->
      <mat-step [stepControl]="banqueForm">
        <form class="pb-3" [formGroup]="banqueForm">
          <div class="felicitaion">
            <div class="circle">
              <span class="fel">Félicitations<br></span>
              <span class="elig mt-3">Vous êtes éligible !<br></span>
              <span class="mens mt-3">Mensualité : {{ mensualite }} DT</span>
            </div>
        
            <div class="avis mt-4">
              Félicitations,votre avis d'éligibilité est favorable, nos experts
              sont à votre disposition pour vous accompagner dans la réalisation
              de votre credit
            </div>
            <div class="mt-3 mb-2">
              Email : contact@easybank.tn<br>
              Tel : 42550600
            </div>
          </div>

          <div class="buttonsContainer">
            <button class="prevBtn" matStepperPrevious>Précédent</button>
            <button class="nextBtn btnHover" [routerLink]="['/inscription']">S'inscrire</button>
            <!--            <button class="nextBtn btnHover" (click)="banqueCheck()">Suivant</button>-->
          </div>
        </form>
      </mat-step>

      <!--      &lt;!&ndash; seven Step &ndash;&gt;-->
      <!--      <mat-step [stepControl]="simulationForm">-->
      <!--        <form class="pb-3" [formGroup]="prospectForm">-->
      <!--          &lt;!&ndash; <ng-template matStepLabel matStepperIcon="edit"> </ng-template> &ndash;&gt;-->
      <!--          <h4>Cordonnées</h4>-->
      <!--          <div class="form-group">-->
      <!--            <label for="firstName">Nom </label>-->

      <!--            <input-->
      <!--              type="text"-->
      <!--              placeholder="Nom"-->
      <!--              id="firstName"-->
      <!--              formControlName="firstName"-->
      <!--              class="form-control"-->
      <!--              [ngClass]="{ 'is-invalid': submitted  && f.firstName.errors }"-->
      <!--              required-->
      <!--            />-->
      <!--            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">-->
      <!--              <div *ngIf="f.firstName.errors.required">Nom est obligatoire</div>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div class="form-group">-->
      <!--            <label for="">prenom</label>-->

      <!--            <input-->
      <!--              type="text"-->
      <!--              placeholder="Prenom"-->
      <!--              formControlName="lastName"-->
      <!--              class="form-control"-->
      <!--              [ngClass]="{ 'is-invalid': submitted && f.lastName.errors,'is-valid': submitted && !f.lastName.errors  }"-->
      <!--              required-->
      <!--            />-->
      <!--            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">-->
      <!--              <div *ngIf="f.lastName.errors.required">Prenom est obligatoire</div>-->
      <!--            </div>-->
      <!--            <div *ngIf="submitted && !f.lastName.errors" class="valid-feedback">-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="form-group">-->
      <!--            <label for="">Date de naissance</label>-->

      <!--            <input-->
      <!--              type="date"-->
      <!--              formControlName="birthday"-->
      <!--              class="form-control"-->
      <!--              [ngClass]="{ 'is-invalid': submitted && f.birthday.errors }"-->
      <!--              required-->
      <!--            />-->
      <!--            <div *ngIf="submitted && f.birthday.errors" class="invalid-feedback">-->
      <!--              <div *ngIf="f.birthday.errors.required">Date de naissance est obligatoire</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="form-group">-->
      <!--            <label for="">Addresse</label>-->

      <!--            <input-->
      <!--              type="text"-->
      <!--              placeholder="Addresse"-->
      <!--              formControlName="address"-->
      <!--              [ngClass]="{ 'is-invalid': submitted && f.address.errors }"-->
      <!--              class="form-control"-->
      <!--              required-->
      <!--            />-->
      <!--            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">-->
      <!--              <div *ngIf="f.address.errors.required">Addresse est obligatoire</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="form-group">-->
      <!--            <label for="">Numero de telephone</label>-->

      <!--            <input-->
      <!--              type="number"-->
      <!--              placeholder="Numero de telephone"-->
      <!--              formControlName="phoneNumber"-->
      <!--              [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }"-->
      <!--              class="form-control"-->
      <!--              required-->
      <!--            />-->
      <!--            <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">-->
      <!--              <div *ngIf="f.phoneNumber.errors.required">Numero de telephone est obligatoire</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="form-group">-->
      <!--            <label for="">Email</label>-->

      <!--            <input-->
      <!--              type="email"-->
      <!--              placeholder="Email"-->
      <!--              formControlName="email"-->
      <!--              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"-->
      <!--              class="form-control"-->
      <!--              required-->
      <!--            />-->
      <!--            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">-->
      <!--              <div *ngIf="f.email.errors?.required" >Email est obligatoire</div>-->
      <!--              <div *ngIf="f.email.errors?.pattern" >Veuillez saisir une adresse email valide</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="form-group">-->
      <!--            <label for="">Entreprise</label>-->
      <!--            <select-->
      <!--              class="form-control"-->
      <!--              placeholder="Entreprise"-->
      <!--              id=""-->
      <!--              formControlName="company"-->
      <!--            >-->
      <!--              &lt;!&ndash; <option value="" disabled selected>Entreprise</option> &ndash;&gt;-->
      <!--              <option-->
      <!--                *ngFor="let item of listEntreprises"-->
      <!--                [value]="item._id"-->
      <!--              >-->
      <!--                {{ item.companyName }}-->
      <!--              </option>-->
      <!--              <div *ngIf="submitted && f.company.errors" class="invalid-feedback">-->
      <!--                <div *ngIf="f.company.errors.required">Entreprise est obligatoire</div>-->
      <!--              </div>-->
      <!--            </select>-->
      <!--          </div>-->
      <!--          <div class="buttonsContainer">-->
      <!--            <button class="prevBtn" matStepperPrevious>Précédent</button>-->
      <!--            <button class="nextBtn btnHover" (click)="saveProspect()" matStepperNext>-->
      <!--              Suivant-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </form>-->
      <!--      </mat-step>-->
    </mat-horizontal-stepper>
  </div>
</div>
